import { Api } from "./config/request";

export const userLogin = (payload) => {
  return Api.postRequestNoHeader("user/v1/login/via-school-code/", payload);
};

export const getChildrenList = () => Api.getListRequest("user/v1/child/");

export const selectChild = (payload) =>
  Api.postRequest("user/v1/child-selector/", payload);

export const otpSend = (payload) => Api.postRequest("user/v1/otp/", payload);
export const callOtpSend = (payload) =>
  Api.postRequest("user/v1/resend-otp/", payload);

export const verifyOtp = (payload) => Api.putRequest("user/v1/otp/", payload);

export const verifyParentOtp = (payload) =>
  Api.postRequest("user/v1/signup/", payload);
export const getEmailOtp = (payload) =>
  Api.postRequest("misc/v1/email-verify/", payload);
export const verifyEmailOtp = (payload) =>
  Api.putRequest("misc/v1/email-verify/", payload);

export const createChildCall = (payload) =>
  Api.postRequest("user/v1/child/", payload);
export const getUserDetail = (headers) => Api.getRequest("user/v1/", headers);

export const getTokenWithEuroData = (payload, euroHeader) =>
  Api.postRequest("user/v1/euro-login/", payload, euroHeader);

export const getFreadomChildren = () => Api.getFliveRequest("children", true);

export const getGradeList = () => Api.getRequest("misc/v1/grade");

export const getChildListByNumber = (number) =>
  Api.getRequest(`user/v1/profiles/?contact_no=${number}`);

export const getRazorPayOrderId = (payload) =>
  Api.postRequest(`misc/v1/firo-subscription/order-id/`, payload);

export const submitOlympiadForm = (payload) =>
  Api.postRequest(`misc/v1/firo-subscription/`, payload);

export const getUtmValues = () =>
  Api.getRequest("misc/v1/clevertap-utm-params/");

export const getSchool = (code) =>
  Api.getRequest("school/v1/validate-school-code/?school_code=" + code);

export const getClass = (data) =>
  Api.getRequest(
    "school/v1/list-school-classes/?school=" +
      data.school +
      "&grade=" +
      data.grade
  );
export const addSchoolToChild = (data) =>
  Api.postRequest("school/v1/link-school-code/", data);
export const User = {
  userLogin,
  getChildrenList,
  selectChild,
  otpSend,
  verifyOtp,
  getUserDetail,
  getTokenWithEuroData,
  getFreadomChildren,
  getGradeList,
  verifyParentOtp,
  createChildCall,
  callOtpSend,
  getSchool,
  getClass,
  addSchoolToChild,
  getEmailOtp,
  verifyEmailOtp,
};
